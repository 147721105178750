.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  text-align: center;
}

input, button {
  display: block;
  margin: 10px auto;
  padding: 10px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  background-color: #333;
  color: #333;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}

p {
  margin-top: 10px;
  font-size: 14px;
  color: #555;
}
